// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getStorage, ref, listAll, getDownloadURL} from "firebase/storage";
import {getFirestore, doc, updateDoc, setDoc, getDoc} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDHr-NAh02r-hNmhs263es6pTJe6cA0TPM",
    authDomain: "v-memoir-prod.firebaseapp.com",
    projectId: "v-memoir-prod",
    storageBucket: "v-memoir-prod.appspot.com",
    messagingSenderId: "389569621170",
    appId: "1:389569621170:web:15a29990aa1a5df08dadab",
    measurementId: "G-0GBXPD1TF5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export async function getImages() {

    const storage = getStorage();
    const listRef = ref(storage, 'images');
    const folder = [];

    const root = await listAll(listRef);
    for (const cateRef of root.prefixes) {

        const category = cateRef.fullPath.split('/')[1];
        const categoryFolder = await listAll(cateRef);

        for (const prefixRef of categoryFolder.prefixes) {

            const path = prefixRef.fullPath.split('/');
            const folderName = path[path.length - 1];
            const urls = [];

            const subFolder = await listAll(prefixRef);

            for (const itemRef of subFolder.items) {
                const url = await getDownloadURL(ref(storage, itemRef.fullPath))
                urls.push(url);
            }

            folder.push({folderName, urls, category});
        }
    }

    await updateOrCreateMain(JSON.stringify(folder));
    return folder;
}

export async function updateOrCreateMain(data) {

    const mainRef = doc(db, "images", "main");

    try {
        await updateDoc(mainRef, {
            data
        });
    } catch (e) {

        await setDoc(mainRef, {
            data
        });
    }
}

export async function getData(skipCache = false) {
    const key = 'all_images';
    const data = localStorage.getItem(key);

    if (data && !skipCache) {
        return JSON.parse(JSON.parse(data).data);
    }

    const mainRef = doc(db, "images", "main");
    const docSnap = await getDoc(mainRef);

    if (docSnap.exists()) {

        const data = docSnap.data();
        localStorage.setItem(key, JSON.stringify(data));
        return JSON.parse(data.data);

    } else {
        console.log("No such document!");
        return [];
    }
}

export async function getHomeImage() {

    const key = 'home_page_image';
    const url = localStorage.getItem(key);

    if (url) {
        return url;
    }

    const storage = getStorage();
    const listRef = ref(storage, 'frontend-app');
    const root = await listAll(listRef);

    if (root) {
        for (const item of root.items) {

            if (item.fullPath.includes('home_page')) {
                const url = await getDownloadURL(ref(storage, item.fullPath));
                localStorage.setItem(key, url);
                return url;
            }
        }
    } else {
        console.log("No such document!");
        return '';
    }
}
