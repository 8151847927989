import './home.css';
import {useState, useEffect} from "react";
import {getData, getHomeImage, getImages} from "../services/firebase";

import Folder from "../folder/folder";

const filters = {
    'All': '', 
     //'Commercial': 'commercial',
     'Event': 'event', 
     'Portrait': 'portrait', 
     'Wedding': 'wedding'
};

const menu = [
    {
        'label': 'Home',
        'route': 'home'
    }, {
        'label': 'Gallery',
        'route': 'gallery'
    }, {
        'label': 'About',
        'route': 'about'
    }
]

function Home() {
    const [key, setKey] = useState('');
    const [folder, setFolder] = useState([]);
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [toggle, setToggle] = useState(false);
    const [folderItems, setFolderItems] = useState([]);
    const [alert, setAlert] = useState(false);

    const [tap, setTap] = useState('home');
    const [imgLoading, setImgLoading] = useState(true);
    const [bgImg, setBgImg] = useState('');

    const fetchRequest = async (skipCache = false) => {
        setBgImg(await getHomeImage());
        const data = await getData(skipCache);
        setFolder(data);
        setFolders(splitArray(data));
        return data;
    };

    function splitArray(data, filter = '') {

        if (filter) {
            data = data.filter(content => content.category === filter);
        }

        if (data.length <= 5) {
            return data.map(arr => [arr]);
        }

        const size = Math.round(data.length / 5);
        const arrayOfArrays = [];

        const arrayOfItems = [[], [], [], []];
        let start = 0;

        while (start < data.length) {

            let index = 0;

            while (index < 4) {

                if (start in data) {
                    arrayOfItems[index++].push(data[start++]);
                } else {
                    break;
                }
            }
        }

        for (let i = 0; i < 5; i++) {

            const items = [];

            for (let k = i * size; k <= (i + 1) * size - 1; k++) {
                if (k < data.length) {
                    items.push(data[k]);
                }
            }

            if (items.length > 0) {
                arrayOfArrays.push(items);
            }
        }

        return arrayOfItems;
    }

    async function onClick() {

        setCounter(counter + 1);
        if (counter === 3) {
            setCounter(0);
            await getImages();
            await fetchRequest(true);
            //await getHomeImage();
            setAlert(true);
        }
    }

    function getFolderItems(urls) {
        setFolderItems(splitArray(urls));
        setToggle(true);
    }

    function clearFolderItems() {
        setToggle(false);
        setFolderItems([]);
    }

    function updateTap(op) {
        setImgLoading(true);
        setTap(op)
    }

    useEffect(() => {
        setLoading(true);
        fetchRequest();
        setLoading(false);
    }, []);

    return (<div className="App sm:px-6 md:px-10 lg:px-52">
            <div className="isolate bg-white">
                <div className="flex flex-1 justify-center">
                    <img className="w-40"
                         src={require('../../src/assets/images/logo.jpg')}
                         alt="logo"/>
                </div>

                <div className="ml-8 flex justify-center mb-4">
                    <ul className="flex flex:row flex-wrap mb-2 justify-start">
                        {menu.map((item) => (
                            <li className={"tracking-tight mr-10 font-medium cursor-pointer " + (tap === item.route ? "text-sky-300" : "")}
                                onClick={() => {
                                    updateTap(item.route);
                                }}
                                key={item.label}>
                                {item.label}
                            </li>))}
                    </ul>
                </div>

                {
                    tap === 'home' &&
                    <div className="relative flex justify-center">
                        <img className="w-full" src={(bgImg)}
                             alt="background-image"
                             onLoad={() => {
                                 setImgLoading(false);
                             }}/>
                        {!imgLoading && <button
                            onClick={() => updateTap('gallery')}
                            className="absolute bottom-10 self-center bg-amber-500 text-white p-2 rounded hover:bg-amber-800 m-2">
                            Let's visit
                        </button>}
                    </div>
                }

                {
                    tap === 'gallery' &&
                    <>
                        {!toggle && <div className="ml-8 flex justify-center mb-4">
                            <ul className="flex flex:row flex-wrap mb-2 justify-start">
                                {Object.keys(filters).map((item) => (
                                    <li className={"tracking-tight mr-10 font-medium cursor-pointer " + (filters[item] === key ? "text-sky-300" : "")}
                                        onClick={() => {
                                            setKey(filters[item]);
                                            setFolders(splitArray(folder, filters[item]));
                                        }}
                                        key={item}>
                                        {item}
                                    </li>))}
                            </ul>
                        </div>}

                        {toggle && <div className="ml-8 flex justify-end mb-4">
                            <ul className="flex flex:row flex-wrap mb-2 justify-center">
                                <li className={"tracking-tight mr-10 font-medium cursor-pointer"}
                                    onClick={() => clearFolderItems()}>
                                    Back
                                < /li>
                            </ul>
                        </div>}
                    </>
                }

                {!loading && !toggle && tap === 'gallery' && <div className="row  mb-100">

                    {folders.length > 0 && <div className="column">
                        {folders[0]?.map((folder, index) => <Folder url={folder.urls[0]} name={folder.folderName}
                                                                    key={index}
                                                                    click={() => getFolderItems(folder.urls)}></Folder>)}

                    </div>}

                    {folders.length > 1 && <div className="column">
                        {folders[1]?.map((folder, index) => <Folder url={folder.urls[0]} name={folder.folderName}
                                                                    key={index}
                                                                    click={() => getFolderItems(folder.urls)}></Folder>)}
                    </div>}

                    {folders.length > 2 && <div className="column">
                        {folders[2]?.map((folder, index) => <Folder url={folder.urls[0]} name={folder.folderName}
                                                                    key={index}
                                                                    click={() => getFolderItems(folder.urls)}></Folder>)}
                    </div>}

                    {folders.length > 3 && <div className="column">
                        {folders[3]?.map((folder, index) => <Folder url={folder.urls[0]} name={folder.folderName}
                                                                    key={index}
                                                                    click={() => getFolderItems(folder.urls)}></Folder>)}
                    </div>}
                </div>}

                {toggle && tap === 'gallery' && <div className="row  mb-100">
                    {folderItems.length > 0 && <div className="column">
                        {folderItems[0]?.map((url, index) => <img src={url} key={index}/>)}

                    </div>}

                    {folderItems.length > 1 && <div className="column">
                        {folderItems[1]?.map((url, index) => <img src={url} key={index}/>)}

                    </div>}

                    {folderItems.length > 2 && <div className="column">
                        {folderItems[2]?.map((url, index) => <img src={url} key={index}/>)}

                    </div>}

                    {folderItems.length > 3 && <div className="column">
                        {folderItems[3]?.map((url, index) => <img src={url} key={index}/>)}

                    </div>}
                </div>}

                {tap === 'about' &&
                    <>
                        <div className="p-10">
                            <h1 className="w-full text-left text-amber-500 font-bold">
                                DARA PHAV
                            </h1>
                            <p className="w-full text-left text-amber-500 font-medium">
                                Photographer
                            </p>
                            <p className="w-full text-left">
                                Montreal - based photographer
                            </p>

                            <br/>
                            <br/>

                            <ul>
                                <li className="text-left mb-2">
                                    <div className="flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-4 m-0 p-0 text-amber-500">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"/>
                                        </svg>
                                        <p className="ml-2"> www.vmemoir.ca</p>
                                    </div>
                                </li>
                                <li className="text-left cursor-pointer mb-2"
                                    onClick={() => window.open('https://www.facebook.com/vmemoirphotography', '_blank', 'noreferrer')}>
                                    <div className="flex">
                                        <svg className="w-4 m-0 p-0  fill-current text-amber-500" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path
                                                d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                                        </svg>
                                        <p className="ml-2">V Memoir Photography</p>
                                    </div>
                                </li>
                                <li className="text-left mb-2">
                                    <div className="flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-4 m-0 p-0 text-amber-500">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                                        </svg>
                                        <p className="ml-2">Daraphav.ca@gmail.com</p>
                                    </div>
                                </li>
                                <li className="text-left ">
                                    <div className="flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-4 m-0 p-0 text-amber-500">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                                        </svg>
                                        <p className="ml-2">438 630 3869</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                }

                <br/>

                {
                    tap === 'home' &&
                    <>
                        <div className="flex justify-center">
                            <ul className="flex flex:row flex-wrap mb-2 justify-start">
                                <li className={"tracking-tight mx-10 my-5 font-medium cursor-pointer"}
                                    onClick={() => window.open('https://www.instagram.com/vmemoirphotography/?hl=en', '_blank', 'noreferrer')}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-rose-400"
                                        fill="currentColor"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                    </svg>

                                </li>
                                <li className={"tracking-tight mx-10 my-5 font-medium cursor-pointer"}
                                    onClick={() => window.open('https://www.facebook.com/vmemoirphotography', '_blank', 'noreferrer')}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 text-blue-400"
                                        fill="currentColor"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                        <div className="flex justify-center">
                            <a href="mailto:daraphav.ca@gmail.com">Daraphav.ca@gmail.com</a>
                        </div>
                    </>
                }

                <br/>
                <br/>
                <br/>

                {!loading && <div className="p-10 text-center text-xs text-gray-200">
                    <p onClick={() => onClick()}>Version 1.0.0</p>
                </div>}

                {alert && <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
                               role="alert">
                    <span className="block sm:inline">The latest data has been updated</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg className="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg"
                         onClick={() => setAlert(false)}
                         viewBox="0 0 20 20"><title>Close</title><path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
                </div>}
            </div>
        </div>
    );
}

export default Home;
