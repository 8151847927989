import {useState} from "react";

function Folder(props) {

    const [load, setLoad] = useState(false);

    return <div className="flex justify-center items-end text-center cursor-pointer" onClick={props.click}>
        {load && <p className="absolute z-50 text-white text-lg font-semibold"
                    style={{"textShadow": "2px 4px 4px rgba(46,91,173,0.6)"}}> {props.name}</p>}

        <img src={props.url} className="relative" onLoad={() => setLoad(true)}/>
    </div>
}

export default Folder;
